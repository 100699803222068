@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./button.css');

body {
  @apply font-lexa
}

.title {
  @apply text-xl font-bold text-black
}

.card-title {
  @apply text-lg font-bold text-black
}

.badge {
  @apply px-2 py-1 text-sm font-bold rounded-2xl uppercase inline-block;
}

.main-container h1 {
  @apply text-2xl font-bold;
}
