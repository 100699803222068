.btn {
  @apply px-4 py-3 rounded-2xl
}

.btn-primary {
  @apply text-white bg-purple-main
}

.btn-primary-outline {
  @apply text-purple-main border border-purple-main bg-transparent
}

.btn-red-outline {
  @apply text-red-main border border-red-main bg-transparent
}
